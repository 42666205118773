@media only screen and (min-width: 768px) { 
    div#content {
        position: absolute;
        left: 210px;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden auto;
        padding: 16px;
    }
}

@media only screen and (max-width: 767px) { 
    div#content {
        position: absolute;
        left: 0px;
        right: 0;
        top: 76px;
        bottom: 0;
        overflow: hidden auto;
        padding: 16px;
    }
    div#sidebar:has(input#sidebarMenuTrigger) + div#content:before {
        top: 0;
        left: 0;
        content: "";
        position: fixed;
        width: 100%;
        height: 0%;
        background-color: rgba(0, 0, 0, 0);
        transition: background-color 0.33s ease-in-out, height 0s linear 0.33s;
        z-index: 99;
    }
    div#sidebar:has(input#sidebarMenuTrigger:checked) + div#content:before {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        transition: background-color 0.33s ease-in-out, height 0.0s linear 0.0s;
    }
}