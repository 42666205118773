@media only screen and (min-width: 768px) {
    div.hero {
        margin: -16px -16px 16px -16px;
        padding: 64px;
        background-size: cover;
        background-position: center top;
        color: white;
        text-align: center;
        font-size: 64px;
        text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black;
    }
}

@media only screen and (max-width: 767px) {
    div.hero {
        margin: -16px -16px 16px -16px;
        padding: 32px;
        background-size: cover;
        background-position: center top;
        color: white;
        text-align: center;
        font-size: 48px;
        text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black;
    }
}