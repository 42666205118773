div.galleryChip {
	height: 185px;
	width: 250px;
	padding: 16px;
	background: black;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	font-family: Montserrat, Noto Sans, sans-serif;
	font-weight: 700;
	color: white;
}

div.galleryChip>img {
    height: 140px;
    width: 250px;
    object-fit: cover;
    filter: blur(2.5px);
    transition: filter 0.25s ease;
}
  
div.galleryChip>img:hover {
    filter: blur(0px);
}

div.galleryChip>a {
    color: unset;
}

@media screen and (max-width: 787px) {
    div.galleryChip {
        width: -webkit-fill-available;
        width: -moz-fill-avaiable;
        text-align: center;
        align-items: center;
    }
}