div.article-container>h1 {
    display: flex;
    align-items: baseline;
    margin: 0;
    & span {
        margin: 0 0 0 16px;
        text-align: right;
    }
}

@media only screen and (min-width: 768px) {
    div.article-container>h1 {
        & span {
            font-size: 21px;
        }
    }
}

@media only screen and (max-width: 767px) {
    div.article-container>h1 {
        & span {
            font-size: 12px;
        }
    }
}

div.article-container>h3, div.article-container>h6 {
    margin: 8px 0;
}