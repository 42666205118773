div#content:has(div#notFound) {
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    & h1 {
        font-size: 128px;
        margin: 0;
        font-family: "Old English Text MT", ui-sans-serif, sans-serif
    }
    & h3 {
        margin-top: -16px
    }
    & code {
        background-color: rgba(255, 255, 255, 0.1);
        padding: 4px;
        border-radius: 4px;
        border: 1px rgba(255, 255, 255, 0.25) solid;
        color: rgba(255, 255, 255, 0.9);
        margin: 0 4px;
    }
}