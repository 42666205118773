div.articles-array {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

@media only screen and (max-width: 767px) {
    div.articles-array {
        font-size: 8px;
    }
}

@media only screen and (max-width: 767px) {
    div.articles-array>div.article-container {
        padding: 12px 16px 16px 16px;
    }
}

@media only screen and (min-width: 768px) {
    div.articles-array>div.article-container {
        padding: 16px;
    }
}

div.articles-array>div.article-container {
    background: snow;
    border: 2.5px solid black;
    border-radius: 8px;
}

@supports (width: -webkit-fill-available) {
    div.articles-array>div.article-container table.MDTable {
        width: -webkit-fill-available;
    }
}

@supports (width: -moz-available) {
    div.articles-array>div.article-container table.MDTable {
        width: -moz-available;
    }
}

@media only screen and (max-width: 767px) {
    div.article-contents {
        font-size: 12px;
    }
}

div.articles-array>div.article-container>div.article-contents p:last-of-type {
    margin-bottom: 0;
}